<template>
  <v-dialog
      v-model="visible"
      :width="width"
    >
      <sx-card :title="title">
        <v-btn class="sx-dialog-close" icon text @click="close">
            <v-icon>mdi-close</v-icon>
        </v-btn>
        <slot></slot> 

        <v-divider></v-divider>
        <template slot="actions">
            <slot name="actions"></slot>
        </template>
      </sx-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'sx-dialog',
    props: {
        title: {
            type: String
        },
        width: {
            type: Number,
            default: 500
        }
    },
    data() {
        return {
            visible: false
        }
    },
    methods: {
        show() {
            this.visible = true
        },
        close() {
            this.visible = false
        }
    }
}
</script>

<style lang="sass">

.sx-dialog-close
    position: absolute
    top: 5px
    right: 5px

</style>