var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: _vm.width },
      model: {
        value: _vm.visible,
        callback: function ($$v) {
          _vm.visible = $$v
        },
        expression: "visible",
      },
    },
    [
      _c(
        "sx-card",
        { attrs: { title: _vm.title } },
        [
          _c(
            "v-btn",
            {
              staticClass: "sx-dialog-close",
              attrs: { icon: "", text: "" },
              on: { click: _vm.close },
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _vm._t("default"),
          _c("v-divider"),
          _c("template", { slot: "actions" }, [_vm._t("actions")], 2),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }